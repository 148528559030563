import React, { useState } from "react";
import Split from "react-split";
import bgimg from "../assets/bg-img.svg";

interface MainContentProps {
  widgetOpen: boolean;
}

const MainContent: React.FC<MainContentProps> = ({ widgetOpen }) => {
  const [isWidgetPinned, setIsWidgetPinnned] = useState(false);

  const createGutter = (index: number, direction: string) => {
    const gutter = document.createElement("div");
    gutter.className = `gutter gutter-${direction} cursor-ew-resize`;
    return gutter;
  };

  const handleToggleWidgetPinned = () => {
    setIsWidgetPinnned((prev) => !prev);
  };

  return (
      <div
        key="left-split"
        className={`flex flex-col justify-center items-center flex-grow p-8 ${
          widgetOpen ? "rounded-l-md" : ""
        } bg-cover bg-no-repeat bg-center space-y-6`}
        style={{ backgroundImage: `url(${bgimg})`, minHeight: "100vh" }}
      >
        {/* Search Bar */}
        <div className="w-full max-w-4xl">
          <div className="flex items-center rounded-md bg-[#F5F5F5] bg-opacity-50 p-2 shadow-md">
            {/* Add search icon here */}
            <input
              className="w-full bg-transparent p-2 text-white placeholder-white outline-none"
              type="search"
              placeholder="Search"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-4 md:gap-8">
          {/* To-Do List */}
          <div className="flex-1 bg-[#323232] rounded-lg shadow-xl backdrop-blur-md min-h-[15rem] max-w-[500px] mx-auto">
            <div className="bg-[#1d1d1d] rounded-t-lg py-3 px-4">
              <h3 className="text-lg font-semibold text-white">
                To-do list (16)
              </h3>
            </div>
            <div className="overflow-auto p-8">
              <ul className="list-disc text-[#bcbec8]">
                {/* Map through your to-do items here */}
                <li>Review and prioritize tasks for the day.</li>
                <li>Conduct a team meeting to discuss goals.</li>
                {/* More to-do items */}
              </ul>
            </div>
          </div>

          {/* Notes */}
          <div className="flex-1 bg-[#fffeff] rounded-lg shadow-xl backdrop-blur-md min-h-[15rem] max-w-[500px] mx-auto">
            <div className="bg-[#f5cb5f] rounded-t-lg py-3 px-4">
              <h3 className="text-lg font-semibold text-white">Notes (12)</h3>
            </div>
            <div className="overflow-auto p-8">
              <p className="text-gray-700">
                Remember to celebrate small wins along the way. Recognition
                boosts morale and fosters a positive team culture. Let's
                acknowledge the effort and progress made by each team member
                this week during our upcoming meeting. Keep up the great work,
                team!
              </p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default MainContent;
