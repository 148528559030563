import React, { useState } from "react";
import MainContent from "./components/MainContent";


function App() {
  const [rightWindowOpen, setRightWindowOpen] = useState(false);

  const handleRightSideBarButtonClick = () => {
    setRightWindowOpen(!rightWindowOpen);
  };

  return (
    <div >
      {/* <TopBar /> */}
      {/* <div className={`flex h-full justify-between`}> */}
        {/* <LeftSideBar /> */}
        <MainContent widgetOpen={rightWindowOpen} />
        {/* <RightSideBar onButtonClick={handleRightSideBarButtonClick} /> */}
      
    </div>
  );
}

export default App;
